import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import config from '../../data/SiteConfig'
import styles from './page.module.scss'

import Title from '../elements/Title'
import { Row, Col, Steps, PageHeader } from 'antd'

const { Step } = Steps

const ContributePage = ({uri}) => (
  <Layout activeSlug={uri}>
    <main>
      <Helmet title={`Comment contribuer ? | ${config.siteTitle}`} />
      <PageHeader
        ghost={false}
        title={<Title>Comment contribuer ?</Title>} 
        className={styles.pageHeader} />
      <Row gutter={config.gutter} className={styles.pageContainer}>
        <Col sm={24} md={{ span: 22, offset: 1 }} lg={{ span: 18, offset: 3 }} xl={{ span: 16, offset: 4 }}>
          <Steps direction="vertical" current={20} progressDot>
            <Step title="Créer un fork du projet sur Github" description={<a href="https://github.com/taiwabisabi/pandawan_articles" title="Repo Article" target="_blank">https://github.com/taiwabisabi/pandawan_articles</a>}/>
            <Step title="Faire un clone de votre fork sur votre machine" description="git clone <fork_repo> pandawan_articles"/>
            <Step title="Mettre ce repo Github en remote origin" description="git remote origin <fork_repo>"/>
            <Step title="Mettre le repo original en remote upstream" description="git remote upstream https://github.com/taiwabisabi/pandawan_articles"/>
            <Step title="Créer un nouvelle branche depuis master" description="git checkout branch <article>"/>
            <Step title="Créer votre nouvelle article dans le bon dossier" description="Suivre les instructions du readme.md"/>
            <Step title="Faire un commit de votre changement" description="git commit -m 'Nouvel article'"/>
            <Step title="Faite un rebase de master" description="git rebase upstream/master"/>
            <Step title="Faite un push de votre branch sur la remote origin" description="git push origin <article>"/>
            <Step title="Depuis votre fork faire un pull request sur la branch staging"/>
          </Steps>
        </Col>
      </Row>
    </main>
  </Layout>
)

export default ContributePage
